<template>
  <div class="bankCard" :style="`background-color:${cardBgColor}`">
    <template v-if="data.accountType == 2">
      <img class="logo" src="@/assets/withdraw/ali1.png" alt="" />
      <img class="bgImg" src="@/assets/withdraw/ali2.png" alt="" />
    </template>
    <template v-else-if="data.accountType == 3">
      <img class="logo" src="@/assets/withdraw/wx1.png" alt="" />
      <img class="bgImg" src="@/assets/withdraw/wx2.png" alt="" />
    </template>

    <div class="cardInfo">
      <template v-if="data.accountType == 1">
        <div class="name">{{ data.bankName }}</div>
        <div class="type">{{ data.bankCardTypeName }}</div>
      </template>
      <template v-else-if="data.accountType == 2">
        <div class="name">支付宝</div>
      </template>
      <template v-else-if="data.accountType == 3">
        <div class="name">微信</div>
        <div class="type">账号</div>
      </template>

      <div class="no">{{ data.accountNo }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {}
  },
  name: '',
  data() {
    return {}
  },
  computed: {
    cardBgColor() {
      if (this.data.accountType == 1) {
        return '#FB4C53'
      } else if (this.data.accountType == 2) {
        return '#4987F5'
      } else {
        return '#0ED26C'
      }
    }
  },
  created() {},
  methods: {
    toDetail() {
      // this.$router.push('/setting/cardDetail')
    }
  }
}
</script>
<style lang='less' scoped>
.bankCard {
  height: 106px;
  box-shadow: 0px 2px 12px 1px rgba(255, 147, 138, 0.4);
  border-radius: 6px;
  background-color: #fb4c53;
  display: flex;
  padding: 18px;
  position: relative;
  overflow: hidden;
  .logo {
    height: 34px;
    width: 34px;
    margin-right: 12px;
    border-radius: 50%;
  }
  .cardInfo {
    color: #ffffff;

    .name {
      height: 22px;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }
    .type {
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
    .no {
      margin-top: 9px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
    }
  }
  .bgImg {
    width: 110px;
    height: 60px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    opacity: 1;
  }
  &:active {
    filter: brightness(0.95);
  }
}
</style>
